//action mode nuit
export const SET_THEME = 'SET_THEME';

//actions settings
export const SET_FONT = 'SET_FONT';

export const SET_COLORATION = 'SET_COLORATION';

export const SET_TRAME = 'SET_TRAME';

export const SET_MASK = 'SET_MASK'; 

export const SET_FONTSIZE = 'SET_FONTSIZE';

export const FORG_IS_ACTIVE = 'FORG_IS_ACTIVE';

export const DEF_IS_ACTIVE  = 'DEF_IS_ACTIVE';

export const SOUND_IS_ACTIVE = 'SOUND_IS_ACTIVE';

export const SET_ACTIVE_PAGE = 'SET_ACTIVE_PAGE';

export const RESET_SETTINGS = 'RESET_SETTINGS';
//action progress bar

export const SET_PROGRESS = 'SET_PROGRESS';
